.minesweeper .difficulty-selector {
  .container {
    margin-top: 20px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    .difficulties-container {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 90%;
      height: calc(50%);
    }
  }
}