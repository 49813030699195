.center-elements {
  display: flex;
  justify-content: center;
  align-items: center;
}

.rounded {
  border-radius: 50%;
}

.head {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  cursor: pointer;

  margin: 5% 2%;
  transition: width 2s, height 2s;


  > * {
    flex-grow: 1;
  }

  .mouth {
    height: 5%;
    width: 42.5%;
    margin-bottom: 10%;
    border-radius: 45%;
    background-color: #7e1212;
    border: 0.7vh solid #a80808;

    .teeth {
      height: 40%;
      justify-content: space-around;
      width: 100%;
      display: none;
    }
  }

  .sclera {
    height: 50%;
    width: 25%;
    position: relative;
    margin: 10% 5% 0 5%;
    background-color: honeydew;

    .pupil {
      height: 30%;
      width: 20%;
      background-color: #111111;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover {
    width: 110%;
    height: 110%;

    .mouth {
      height: 35%;
      transition: all 0.2s ease-out;

      .teeth {
        display: flex;

        * {
          height: 100%;
          width: 25%;
          background-color: honeydew;

          &:first-child {
            margin-right: -20%;
            border-radius: 40% 12.5% 15% 5%;
          }

          &:last-child {
            margin-left: -20%;
            border-radius: 12.5% 50% 11% 5%;
          }
        }
      }
    }
  }

}