.explosion {
  width: 100%;
  height: 100%;
  animation: fire 0.15s linear infinite;
}

@keyframes fire {
  from {
    background-color: #ff4d00;
  }
  33% {
    background-color: #ff8000;
  }
  66% {
    background-color: #ffbb35;
  }
  to {
    background-color: #ffff00;
  }
}