.indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 100%;
    width: 33.3%;
}

.controlPanel {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 10%;
    background-color: darkorchid;
    font-weight: bold;
    color: aliceblue;
}

.indicator img {
    height: 75%;
    max-height: 40px;
    margin-right: 0.1vw;
}

.indicator p {
    width: 30%;
    text-align: right;
}

.indicator .change-difficulty {
    cursor: pointer;
    margin: 0;
    padding: 0 5px 0 5px;
    border-radius: 3px;
}

.indicator .change-difficulty:hover {
    background-color: aliceblue;
    color: darkorchid;
}