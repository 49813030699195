:root {
  --background-color: #204020;
  --text-color: yellowgreen;
  --border-radius: 10px;
}

.box {
  position: fixed;
  color: var(--text-color);
  background-color: var(--background-color);
  padding: 5px;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;

  h1 {
    margin: auto;
  }

  .box-resizer {
    width: 20px;
    height: 20px;
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: red;
    border-radius: 5px 0 var(--border-radius) 0;
    cursor: se-resize;
  }

  .info-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 2em;
    font-weight: bold;
    border-radius: var(--border-radius);

    .controls {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      width: 100%;

      button {
        border: none;
        border-radius: 5px;
        font-size: 40%;
        background: darkorange;
        color: black;
        font-weight: bold;
        cursor: pointer;
        margin: 5px;
        padding: 5px;
      }
    }
  }
}