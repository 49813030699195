.box-header {
    position: relative;
    background-color: darkorange;
    border-radius: 10px 10px 0 0;
    width: calc(100% + 10px);
    height: 20px;
    display: flex;
    flex-direction: row;
    margin: -5px -5px 5px -5px;
    cursor: move;
    z-index: 1;
}

.box-header .mover {
    flex-grow: 1;
    padding-left: 12px;
    color: darkblue;
    font-size: 15px;
}

.box-header .close-button {
    border-radius: 0 10px 0 0;
    font-weight: bold;
    font-size: 11px;
    border: solid 2px darkorange;
}

.box-header .close-button:hover {
    background-color: #a33939;
}
