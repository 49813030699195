.game-field {
    position: relative;
    height: 90%;
    width: 100%;
}

.game-field-row {
    display: flex;
    width: 100%;
}

.game-field-row div {
    display: flex;
}

.game-field-cell {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0;
    font-weight: bold;
    color: #4e0b7c;
    /*background-color: rgb(78, 11, 124);*/
    outline: solid 1px #75677e;
}

.game-field-cell img {
    height: 60%;
}