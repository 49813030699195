.field {
  border: 1px solid red;
  background-color: #252533;
  display: flex;
  flex-direction: column;
  position: relative;

  .field-row {
    display: flex;
  }
}
