.mode-selector {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: sticky;
    top: 100%;
    width: 100%;
    height: 5%;
}

.mode-selector img {
    transition: 0.5s;
    width: 40px;
    height: 40px;
}