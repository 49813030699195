//noinspection ALL
@property --a {
  syntax: '<angle>';
  inherits: false;
  initial-value: 0deg;
}

.bonus-box {
  --a: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: repeating-conic-gradient(from var(--a) at 50%, #FF0000 0deg 10deg, #FFA500 10deg 20deg, #FFFF00 20deg 30deg, #7FFF00 30deg 40deg, #00FFFF 40deg 50deg, #0000FF 50deg 60deg, #9932CC 60deg 70deg, #FF1493 70deg 80deg);
  animation: gradient 4s linear infinite;

  .bonus {
    height: 85%;
    width: 85%;
  }
}

@keyframes gradient {
  from {
    --a: 0deg;
  }
  to {
    --a: 360deg;
  }
}