.note-editor {
  flex-grow: 1;

  textarea {
    width: 100%;
    height: 100%;
    padding: 0;
    resize: none;
    background: none;
    border: none;
    color: var(--text-color);

    &:focus-visible {
      outline: none;
    }

    &::-webkit-scrollbar {
      background: none;
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      width: 5px;
      background: darkorange;
    }
  }
}