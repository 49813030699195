.bomberman {
  border: 0.1px solid black;

  .game-area {
    width: 100%;
    height: 90%;
    position: relative;
    overflow: hidden;

    .scene {
      width: 100%;
      height: 100%;
      overflow: hidden;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
}